import { useEffect } from 'react'
import classNames from 'classnames'
import { renderIf } from 'xact'
import * as paths from '../../paths'
import { useBlog } from '../../stores/blogStore'
import { NavLink, Navigate, useLocation } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import Post from './Post'
import styles from './Article.module.css'

export default function Article() {
  const blogIndex = useBlog();
  useEffect(() => {
    if (blogIndex.items.length === 0) {
      blogIndex.init();
    }
  }, [blogIndex]);
  const location = useLocation();
  const url = location.pathname.split('/');
  const content = url[url.length - 1];
  const item = blogIndex.map[content];
  return (
    <div className='d-flex flex-row'>
      {renderIf(blogIndex.items.length > 0 && item == null, <Navigate to={paths.blog} replace={true} />)}
      <div className={styles.index}>
        <NavLink to={paths.blog}>Back</NavLink>
      </div>
      <Container className='mb-5'>
        <Row>
          <Col sm={12} md={{ offset: 0, span: 11 }}>
            <div className={classNames('title', styles.title)}>
              {item?.title ?? ''}
            </div>
            <div className={styles.timestamp}>
              {item == null ? '' : new Date(item?.timestamp).toLocaleDateString()}
            </div>
            <div className={styles.article}>
              <Post name={content} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
