import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import * as paths from '../../paths'
import styles from './Solutions.module.css'

export interface CardInfo {
    title: string;
    text: string;
}

export function CallToAction() {
    return <div className={classNames('title', styles.title)}>
      <div className='lead pb-5'>
        We’re excited to connect and learn about how we can partner to support your workforce management!
      </div>
    </div>
  }
  
  
export function ScheduleAction() {
    return <div className={styles.schedule}>
        <NavLink to={paths.contact}>Schedule a Consultation</NavLink>
    </div>
}