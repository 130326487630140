import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import * as paths from '../../paths'
import { ReactComponent as Icon } from '../../assets/pin.svg'
import styles from './Company.module.css'

export default function About() {
  return (
    <div className={styles.container}>
      <div className='d-flex flex-row justify-content-between'>
        <div></div>
        <div className={styles.title}>About Us</div>
        <div className={styles.image}><Icon /></div>
      </div>
      <div className={styles.content}>
        <div className={classNames(styles.heading, styles.big)}>
          What We Do:
        </div>
        <div className={classNames(styles.section, 'lead')}>
          Our mission is to make AI technology accessible for businesses of every size,
          helping teams unlock their potential with innovative training solutions.
          We’re driven by the belief that AI can elevate workforce performance through
          realistic simulations, knowledge development, and performance coaching.
        </div>
        <div className={classNames(styles.section, 'lead')}>
          With our tools, teams can hone their skills in a safe, simulated environment,
          allowing employees to build confidence and sharpen their responses to
          diverse scenarios—without the risks of live interactions. By leveraging AI-driven simulations,
          we empower businesses to enhance their workforce’s capabilities,
          ensuring they’re prepared to provide exceptional service and work collaboratively in fast-paced settings.
        </div>
        <div className={classNames(styles.section, 'lead')}>
          For managers and leaders, our platform provides invaluable,
          insights into training outcomes. This feedback enables leaders
          to monitor performance, identify skill gaps,
          and adapt training to meet specific needs.
        </div>
        <div className={classNames(styles.heading, styles.big)}>
          Who We Are:
        </div>
        <div className={classNames(styles.section, 'lead')}>
          We’re a team of dedicated innovators, educators, and technology enthusiasts who are passionate
          about transforming the way people learn and grow in their careers. United by our commitment to
          accessibility, we believe that AI-driven training should be both powerful and intuitive,
          capable of unlocking potential across all levels of an organization.
        </div>
        <div className={classNames(styles.section, 'lead')}>
          Founded in Seattle, Washington, we're expanding across the US market.
          Interested in joining our team?  We embrace a flexible, remote-friendly
          work culture that values diverse perspectives and experiences, no matter
          where you’re located. (<NavLink className={styles.navlink} to={paths.careers}>Careers</NavLink>)
        </div>
        <div className={classNames(styles.heading, styles.big)}>
          Why We Do It:
        </div>
        <div className={classNames(styles.section, 'lead')}>
          At the heart of our work is the belief that empowered individuals create stronger,
          more resilient organizations. By transforming training into an engaging,
          personalized experience, we’re building a future where businesses and their people
          grow together, achieving their highest potential.
        </div>
        <div className={classNames(styles.section, 'lead')}>
          Guided by empathy and a deep understanding of modern workplace challenges, we’re here
          to make a real difference. Our vision is to build more capable, confident teams through
          technology that puts human experience first.
        </div>
      </div>
    </div>
  );
}
