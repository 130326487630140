import classNames from 'classnames'
import { renderIf } from 'xact'
import { Button } from 'react-bootstrap'
import styles from './Viewer.module.css'

export interface IViewerProps {
    document: string;
    show: boolean;
    children: string | JSX.Element;
    onHide: () => void;
}

export default function Backdrop(props: IViewerProps) {
    return (
        renderIf(props.show ?? true,
            <div className={classNames(styles.backdrop, styles.blur, styles.fullscreen)} >
                <div className={styles.viewer}>
                    <div className={classNames(styles.title)}>{props.children}</div>
                    <div className={styles.content}>
                        <iframe title='viewer' className='privacy-frame' src={props.document} />
                    </div>
                    <div className={styles.footer}>
                        <Button variant='primary' size='sm' onClick={props.onHide}>
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        ));
}