import * as routes from './routes'

// general
export const home = routes.home;
export const pricing = `${routes.home}${routes.pricing}`;
export const contact = `${routes.home}${routes.contact}`;

// company info
export const about = `${routes.home}${routes.about}`;
export const careers = `${routes.home}${routes.careers}`;

// solutions
export const solutions = `${routes.home}${routes.solutions}`;
export const solutionretail = `${routes.home}${routes.solutions}/${routes.solutionretail}`;
export const solutioncallcenter = `${routes.home}${routes.solutions}/${routes.solutioncallcenter}`;
export const solutionfoodservice = `${routes.home}${routes.solutions}/${routes.solutionfoodservice}`;
export const solutionadmin = `${routes.home}${routes.solutions}/${routes.solutionadmin}`;

// blog
export const blog =  `${routes.home}${routes.blog}`;

// landing
// export const landing1 = `/try`;
