import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { config } from '../config'
import * as paths from '../paths'
import Viewer from '../components/viewer/Index'
import styles from './Footer.module.css'

export default function Footer() {
    const [showPrivacy, setShowPrivacy] = useState(false);
    const navigate = useNavigate();

    function onAbout() {
        navigate(paths.about);
    }

    function onCareers() {
        navigate(paths.careers);
    }

    function onContact() {
        navigate(paths.contact);
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.action} onClick={onAbout}>About SkillPulse</div>
                <div className={styles.action} onClick={onContact}>Contact Us</div>
                <div>© 2024 SkillPulse AI, All Rights Reserved</div>
                <div className={styles.action} onClick={() => setShowPrivacy(true)}>Privacy Policy</div>
                <div className={styles.action} onClick={onCareers}>Careers</div>
            </div>
            <Viewer
                show={showPrivacy}
                document={`${config.app.Content}/legal/privacy-policy.pdf#fitToHeight`}
                onHide={() => setShowPrivacy(false)}>
                Skill Pulse AI - Privacy Policy
            </Viewer>
        </>
    );
}