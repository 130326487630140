import classNames from 'classnames';
import logo from '../../assets/react.svg'
import styles from './Info.module.css'

interface InfoLink {
  display: string;
  target: string;
}

const learnLinks: InfoLink[] = [
  { display: 'React', target: 'https://reactjs.org' },
  { display: 'TypeScript', target: 'https://www.typescriptlang.org/cheatsheets' },
  { display: 'Jest', target: 'https://jestjs.io/docs/setup-teardown' },
  { display: 'BootStrap', target: 'https://getbootstrap.com/docs/5.3/utilities/api' },
  { display: 'React Bootstrap', target: 'https://react-bootstrap.netlify.app' },
  { display: 'React Hook Form', target: 'https://react-hook-form.com/' },
  { display: 'Zustand', target: 'https://github.com/pmndrs/zustand/blob/main/readme.md' },
  { display: 'DeepGram', target: 'https://developers.deepgram.com/docs' },
  { display: 'AJV', target: 'https://ajv.js.org/api.html' },
  { display: "BullMQ", target: 'https://docs.bullmq.io' },
  { display: "IORedis", target: 'https://redis.github.io/ioredis/classes/Redis.html' },
  // { display: '', target: '' },
  // { display: '', target: '' },
];

const manageLinks: InfoLink[] = [
  { display: 'BitBucket', target: 'https://bitbucket.org' },
  { display: 'Digital Ocean', target: 'https://cloud.digitalocean.com' },
  { display: 'PM2', target: 'https://pm2.keymetrics.io/docs/usage/process-management/' },
  { display: 'nginx', target: 'https://nginx.org/' },
  // { display: '', target: '' },
  // { display: '', target: '' },
];

function formatLink(key: string, link: InfoLink): JSX.Element {
  return (
    <li key={key}>
      <a
        href={link.target}
        target='_blank'
        rel='noopener noreferrer'>
        {link.display}
      </a>
    </li>
  );
}

export default function Info() {
  return (
    <>
      <div className={classNames([styles.logo], 'd-flex', 'flex-column')}>
        <img src={logo} className={styles.logo} alt='logo' />
      </div>
      <div className={classNames([styles.links], 'container')}>
        <div className='d-flex flex-row justify-content-center'>
          <div>
            <div className='fw-bold'>Learn</div>
            <ul>
              {learnLinks.map((link, index) => formatLink(`learn${index}`, link))}
            </ul>
          </div>
          <div className='ps-5'>
            <div className='fw-bold'>Manage</div>
            <ul>
              {manageLinks.map((link, index) => formatLink(`manage${index}`, link))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
