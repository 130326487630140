import { useEffect, useState } from 'react';
import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import * as form from "react-hook-form";
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import * as paths from '../../paths'
import * as contact from '../../services/contact'
import * as xtypes from 'xtypes'
// import IconLogo from '../assets/react.svg'
import styles from './Contact.module.css'

interface ContactLabelProps {
  text: string;
  top?: boolean;
}
interface ContactFieldProps {
  children: JSX.Element;
}

interface ContactInputProps {
  name: string;
  type?: string;
  placeholder: string;
  required?: boolean;
}

export default function Contact() {
  const [isSent, setSent] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const hooks = form.useForm();
  useEffect(() => {
    if (isSent) {
      const timer = setTimeout((() => navigate(paths.home)), 10000);
      return () => clearTimeout(timer);
    }
  }, [isSent, location, navigate])

  function onSubmit(data: form.FieldValues) {
    contact.postRequest(data as xtypes.site.IContact);
    setSent(true);
  }

  function onContinue() {
    navigate(paths.home);
  }

  function onInvalid(errors: form.FieldErrors<form.FieldValues>) {
    // $$$ ANYTHING ???
  }

  function onValidatePhone(name: string, value: string): boolean {
    if (value == null || value.trim() === '') {
      return true;
    }
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (!phoneRegex.test(value)) {
      hooks.setError(name, { message: 'Enter a valid phone number' }, { shouldFocus: true });
      return false;
    }
    const formattedValue = value.replace(phoneRegex, "($1) $2-$3");
    hooks.setValue(name, formattedValue);
    return true;
  }

  function ContactLabel(props: ContactLabelProps) {
    return (
      <Col className={classNames('fw-bold mb-sm-0 mb-md-3 text-sm-start text-md-end', { 'd-flex flex-column align-self-start': props.top })} sm={12} md={2}>
        {props.text}:
      </Col>);
  }

  function ContactField(props: ContactFieldProps) {
    return (
      <Col className={'mb-3'} sm={12} md={9}>
        {props.children}
      </Col>);
  }

  function ContactInputText(props: ContactInputProps) {
    return (
      <ContactField>
        <input
          className='form-control'
          id={props.name}
          key={props.name}
          type={props.type ?? 'text'}
          tabIndex={0}
          placeholder={props.placeholder}
          {...hooks.register(props.name, { required: props.required ?? false })}
        />
      </ContactField>);
  }

  function ContactInputPhone(props: ContactInputProps) {
    return (
      <ContactField>
        <input
          className='form-control'
          id={props.name}
          key={props.name}
          type={'tel'}
          tabIndex={0}
          placeholder={props.placeholder}
          {...hooks.register(props.name, { required: false, validate: (value, _) => onValidatePhone(props.name, value) })}
        />
      </ContactField>);
  }

  return (
    <form onSubmit={hooks.handleSubmit(onSubmit, onInvalid)}>
      <Container>
        <div className={styles.title}>
          <div className='display-4 fw-bold pb-2'>Contact Our Team</div>
          <div className='pb-1'>
            Complete this form to start a conversation about<br />
            empowering your team and enhancing customer experiences.<br />
            (leaving you more time to grow your business)
          </div>
        </div>
        <Row className='d-flex align-items-center gx-3 gy-1 mb-3' sm={1} md={2}>
          {/* BUSINESS */}
          <ContactLabel text='Business' />
          <ContactInputText name='business' placeholder='Your business name' required={true} />
          {/* INDUSTRY */}
          <ContactLabel text='Description' />
          <ContactInputText name='industry' placeholder='The type of business' required={true} />
          {/* NAME */}
          <ContactLabel text='Name' />
          <ContactInputText name='name' placeholder='Your full name' required={true} />
          {/* EMAIL */}
          <ContactLabel text='Email' />
          <ContactInputText type='email' name='email' placeholder='Your email address' required={true} />
          {/* EMAIL */}
          <ContactLabel text='Phone' />
          <ContactInputPhone type='tel' name='phone' placeholder='Your phone number (optional)' />
          {/* EMAIL */}
          <ContactLabel text='Message' top={true} />
          <ContactField>
            <textarea
              className='form-control'
              id='message'
              key='message'
              tabIndex={0}
              placeholder='Please feel free to include a message to let us know what’s on your mind (optional)'
              {...hooks.register('message', {})} />
          </ContactField>
          {/* BUTTONS */}
          <Col className='d-grid gap-2 d-md-flex justify-content-md-end' sm={12} md={11}>
            <button
              className='btn btn-outline-secondary'
              type='reset'
              tabIndex={0}
              onClick={() => hooks.reset()}
            >
              Reset
            </button>
            <button
              className='btn btn-primary'
              type='submit'
              tabIndex={0}
            >
              Send
            </button>
          </Col>
        </Row>
      </Container>
      <div className={styles.note}>
        <Container>
          <Row className='gx-3' sm={1} md={2}>
            <Col className={classNames(styles.notes, 'small')} sm={12} md={{ offset: 2, span: 9 }}>
              You may also reach out to us anytime over email:
              <ul>
                <li className='fw-bold'>Sales:&nbsp;<a href='mailto:sales@skillpulse.ai'>sales@skillpulse.ai</a></li>
                To explore solutions, request a demonstration, and discover how to seamlessly integrate into your operations.
              </ul>
              or
              <ul>
                <li className='fw-bold'>Support:&nbsp;<a href='mailto:support@skillpulse.ai'>support@skillpulse.ai</a></li>
                For technical guidance, setup questions, and best practices for effective use..
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal show={isSent} size='lg' centered backdrop autoFocus onHide={() => onContinue()}>
        <Modal.Header>
          <Modal.Title className='text-primary display-6 fw-bold'>Information Sent!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.confirm}>
            <div className='lead fw-bold'>
              Thank you for reaching out! Our team will get back to you promptly.
            </div>
            <div>
              Our team is excited to connect with you and will review your request carefully.
            </div>
            <Button type='submit' variant='primary' onClick={() => onContinue()}>
              Continue
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </form>
  );
}
