import classNames from 'classnames'
import { CardGroup, Card, Col, Container, Row, CardBody } from 'react-bootstrap'
import { CardInfo, CallToAction, ScheduleAction } from './Solutions'
import styles from './Solutions.module.css'

const industries: CardInfo[] = [
  {
    "title": "Administrative Support",
    "text": "Develop core skills in task prioritization, time management, and organizational tools to enhance efficiency and ability to support dynamic team environments."
  },
  {
    "title": "Office Management",
    "text": "Build expertise in coordinating office functions, mastering tools for scheduling, resource allocation, and team communication to create seamless operations."
  },
  {
    "title": "Public Relations",
    "text": "Learn essential skills in strategic communication, media handling, and relationship-building to effectively manage public engagement."
  }
]

const areas: CardInfo[] = [
  {
    "title": "Point of Sale",
    "text": "Training staff to take accurate and efficient orders, ensuring all customer preferences and special requests are captured while minimizing errors. This builds trust and creates a seamless dining experience."
  },
  {
    "title": "Customer Assistance",
    "text": "Providing comprehensive training on menu offerings, including ingredients, preparation methods, and dietary accommodations, so service staff can confidently answer customer questions and make recommendations."
  },
  {
    "title": "Upselling Techniques",
    "text": "Developing strategies for recommending complementary or premium items to enhance the customer experience and increase average check sizes without being pushy."
  },
  {
    "title": "Customer Conflict",
    "text": "Equipping team members with skills to calmly and professionally handle disputes or complaints from customers, turning potential negative situations into positive outcomes."
  },
  {
    "title": "Staff Conflict",
    "text": "Teaching conflict resolution techniques to help team members and managers mediate and resolve interpersonal issues, fostering a harmonious and collaborative work environment."
  }
]

export default function Admin() {
  return (
    <>
      <div className={classNames('title', styles.title)}>
        <div className='display-6 fw-bold pb-2'>
          Office Operations & Public Relations
        </div>
        <div className='lead fw-bold pb-2'>
          Programs specifically tailored to the unique needs for any operational model.
        </div>
      </div>
      <Container>
        <CardGroup>
          {industries.map((industry, index) =>
            <Card border='primary' key={`i${index}`}>
              <CardBody>
                <Card.Title className={classNames('title fw-bold fs-4')}>
                  {industry.title}
                </Card.Title>
                <Card.Text className={''}>
                  {industry.text}
                </Card.Text>
              </CardBody>
            </Card>
          )}
        </CardGroup>
        <ScheduleAction />
        <Row>
          <Col sm={{ span: 8, offset: 2 }}>
            <h3 className='fw-bold'>All Programs Offer:</h3>
            <ul className={classNames('lead', styles.list)}>
              <li><strong>Quick Onboarding:</strong> Seamlessly get started with programs tailored to every service style.</li>
              <li><strong>Effortless Customization:</strong> Adapt programs to fit your unique business needs.</li>
              <li><strong>Ongoing Relevance:</strong> Easily update content to stay aligned with your evolving requirements.</li>
              <li><strong>Anywhere Accessibility:</strong> Access tools and resources from any location.</li>
              <li><strong>Actionable Metrics:</strong> Track staff progress with meaningful, insightful data.</li>
            </ul>
            <ScheduleAction />
          </Col>
        </Row>
        <Row className='g-3' sm={1} md={2} xl={3}>
          {areas.map((area, index) =>
            <Col key={`a${index}`} sm={12} md={6} xl={4}>
              <Card className={styles.card} border='primary' key={`card-${index}`}>
                <CardBody>
                  <Card.Title className={classNames('title fw-bold')}>
                    {area.title}
                  </Card.Title>
                  <Card.Text className=''>
                    {area.text}
                  </Card.Text>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
        <ScheduleAction />
        <CallToAction />
      </Container>
    </>
  );
}
