// import classNames from 'classnames';
import { useState } from 'react';
import styles from './Fonts.module.css'

function FontDemo() {
  return (
    <>
      {
        Object.keys(styles).filter(style => style !== 'demo' && style !== 'example').map(style =>
          <div className={styles[style]}>
            {style}: The quick brown fox jumped over the lazy hen, giggling.
          </div>
        )
      }
    </>
  );
}

const fonts: string[] = [
  'Arial',
  'Asap',
  'DM Sans',
  'Geneva',
  'Noto Sans',
  'Open Sans',
  'Poppins',
  'Raleway',
  'Roboto',
  'sans-serif',
  'Segoe UI',
  'Sen',
  'Tahoma',
  'Verdana',
];

export default function Info() {
  const [fontA, setFontA] = useState('Poppins');
  const [fontB, setFontB] = useState('Arial');

  return (
    <div className={styles.demo}>
      <div className={styles.example} style={{ fontFamily: fontA }}>
        <select value={fontA} onChange={(e) => setFontA(e.currentTarget.value)}>
          {
            fonts.map(f =>
              <option>{f}</option>
            )
          }
        </select>
        <FontDemo />
      </div>
      <div className={styles.example} style={{ fontFamily: fontB }}>
        <select value={fontB} onChange={(e) => setFontB(e.currentTarget.value)}>
          {
            fonts.map(f =>
              <option>{f}</option>
            )
          }
        </select>
        <FontDemo />
      </div>
    </div >
  );
}
