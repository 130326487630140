import { useEffect, useState } from 'react'
import dompurify from 'dompurify'
import * as blog from '../../services/blog'

interface InnerHtml {
    __html: string;
}

interface IPostProps {
    name: string;
}

export default function Post(props: IPostProps) {
    const [post, setPost] = useState<InnerHtml>();
    // const [error, setError] = useState(false);
    useEffect(() => {
        getPosts();
        async function getPosts() {
            try {
                const content = await blog.getPost(props.name);
                const clean = dompurify.sanitize(content);
                setPost({ __html: clean });
            } catch {
                // setError(true);
            }
        }
    }, [props.name]); // $$$ ZUSTAND

    return (
        <div dangerouslySetInnerHTML={post} />
    );
}

