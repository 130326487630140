import React from 'react'
import ReactDOM from 'react-dom/client'
import log from 'loglevel'
import { logging, theme } from 'xact'
import { config } from './config'
import App from './App'
// Global styles
import 'bootstrap/dist/css/bootstrap.css'
import './styles/root.css'

async function init(): Promise<void> {
  logging.init();
  await config.load();
  theme.config();
  
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

init().catch(err => log.error(err));
