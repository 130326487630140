import { create } from 'zustand'
import * as careers from '../services/careers'
import * as xtypes from 'xtypes'

export interface PositionsState {
    positions: xtypes.site.IPosition[],
    error?: Error;
    init: () => Promise<void>;
}

export const usePositions = create<PositionsState>((set) => ({
    positions: [],
    init: async () => {
        try {
            const positions = await careers.getPositions()
            set({ positions });
        } catch (err: any) {
            set({ error: err });
        }
    },
}));
