import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import * as routes from './routes'
import * as layouts from './layout'
import * as views from './views'

const router = createBrowserRouter(
    [
        {
            path: routes.home,
            element: <layouts.Main />,
            children: [
                { index: true, element: <views.Home /> },
                { path: routes.contact, element: <views.Contact /> },
                { path: routes.pricing, element: <views.Pricing /> },
                { path: routes.about, element: <views.About /> },
                { path: routes.careers, element: <views.Careers /> },
                {
                    path: routes.solutions,
                    children: [
                        { index: true, element: <views.Solutions /> },
                        { path: routes.solutionadmin, element: <views.SolutionsAdmin /> },
                        { path: routes.solutioncallcenter, element: <views.SolutionsCallCenter /> },
                        { path: routes.solutionfoodservice, element: <views.SolutionsFoodService /> },
                        { path: routes.solutionretail, element: <views.SolutionsRetail /> },
                    ]
                },
                {
                    path: routes.blog,
                    children: [
                        { index: true, element: <views.BlogIndex /> },
                        { path: '*', element: <views.BlogArticle /> },
                    ]
                },
                {
                    path: routes.hidden,
                    children: [
                        { index: true, element: <views.DevInfo /> },
                        { path: routes.fonts, element: <views.DevFonts /> },
                    ]
                },
            ]
        },
        { path: '*', element: < views.NotFound /> }
    ],
    {
        future: { // $$$ REMOVE AFTER BECOMES V7 DEFAULTS
            v7_relativeSplatPath: true,
            v7_fetcherPersist: true,
            v7_normalizeFormMethod: true,
            v7_partialHydration: true,
            v7_skipActionErrorRevalidation: true,
        }
    });

export default function App() {
    return (
        <RouterProvider
            router={router}
            future={{ v7_startTransition: true }} // $$$ REMOVE AFTER BECOMES V7 DEFAULTS
        />
    );
}
