import { create } from 'zustand'
import { resource } from 'xact'
import * as paths from '../paths'
import { config } from '../config'

export interface MarqeeMessage {
    text: string;
    target: string;
}

export interface MarqueeState {
    content: MarqeeMessage[],
    error?: Error;
    init: () => Promise<void>;
}

const contentUrl = `${config.app.Content}/site/marquee.json`

export const useMarquee = create<MarqueeState>((set) => ({
    content: [],
    init: async () => {
        try {
            const content = await resource.load<MarqeeMessage[]>(contentUrl);
            set({ content });
        } catch (err: any) {
            set({ content: defaultContent, error: err });
        }
    },
}));

const defaultContent: MarqeeMessage[] = [
    // { target: AppPaths.home, text: 'New training programs available for Food Service and Retail Sales!' },
    { target: paths.contact, text: 'Limited-time offer: Personalized onboarding at no cost!' },
    // { target: AppPaths.home, text: 'New training programs available for Call Center and Administrative Services!' },
    { target: paths.contact, text: 'Request your complimentary demonstration now!' },
    { target: paths.solutions, text: 'Discover how AI can enhance your team’s skills and performance!' },
    // { target: AppPaths.home, text: '' },
];
