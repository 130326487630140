import { useEffect, useState } from 'react'
// import classNames from 'classnames'
import { usePositions } from '../../stores/positionsStore'
import { config } from '../../config'
import * as xtypes from 'xtypes'
import { ReactComponent as Icon } from '../../assets/rocket.svg'
import Viewer from '../../components/viewer/Index'
import styles from './Company.module.css'

export default function Careers() {
  const [position, setPosition] = useState<xtypes.site.IPosition>();
  const state = usePositions();
  useEffect(() => {
    if (state.positions.length === 0) {
      state.init();
    }
  }, [state]);

  return (
    <div className={styles.container}>
      <div className='d-flex flex-row justify-content-between'>
        <div></div>
        <div className={styles.title}>Ready for a Change?</div>
        <div className={styles.image}><Icon /></div>
      </div>
      <div className={styles.content}>
        <div className={styles.heading}>
          Us:
        </div>
        <div className={styles.section}>
          Driven by innovation, collaboration, and the shared passion to create meaningful solutions for business
          and the people who make it happen.
        </div>
        <div className={styles.heading}>
          You:
        </div>
        <div className={styles.section}>
          Thrives on challenges, values constant growth, loves learning, and eager to deliver impact.
        </div>
        <div className={styles.heading}>
          Culture:
        </div>
        <div className={styles.section}>
          We’re looking for talented individuals who aren’t just seeking a job,
          but a place to develop, evolve, and grow alongside a dynamic group of innovators.
          By joining us, you’ll be stepping into an environment where your skills will be valued,
          your curiosity encouraged, and your potential unlocked.
        </div>
        <div className={styles.heading}>
          Where:
        </div>
        <div className={styles.section}>
          We are based in <i>Seattle, Washington</i> but you can be from anywhere.
          You’ll collaborate with talented colleagues with online tools and
          receive all the support you need to successfully contribute.
        </div>
        <div className={styles.heading}>
          How:
        </div>
        <div className={styles.section}>
          See a position that's perfect for you?  Send us your resume along with
          a brief statement about who you are, why you’re interested in the role,
          and any other explanation or description you'd like to share to:
        </div>
        <div className={styles.section}>
          <li className={styles.email}><a href='mailto:careers@skillpulse.ai'>careers@skillpulse.ai</a></li>
        </div>
        <div className={styles.section}>
          We look forward to meeting you!
        </div>
        <div className={styles.heading}>
          Positions:
        </div>
        {
          state.positions.map((p, index) =>
            <div key={index} className={styles.position}>
              <div className={styles.positiontitle}>
                <div>{p.position}</div>
                <div className={styles.positiondoc} onClick={() => setPosition(p)}>Details</div>
              </div>
              <div className={styles.positionsummary}>
                {p.summary}
              </div>
            </div>)
        }
      </div >
      <Viewer
        show={position != null}
        document={`${config.app.Content}/positions/${position?.requirements}`}
        onHide={() => setPosition(undefined)}>
        {position?.position ?? ''}
      </Viewer>
    </div>);
}
