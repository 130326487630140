import { resource } from 'xact'

interface AppConfig {
    PortAPI?: string;
    SignIn: string;
    Blog: string;
    Content: string;
}

const defaultConfig: AppConfig = {
    PortAPI: '8080',
    SignIn: 'http://skillpulse.local:3000',
    Blog: 'dev',
    Content: 'https://content.skillpulse.ai'
};

const configUrl = "/config.json";

class ConfigLoader {
    public app: AppConfig = defaultConfig;

    public async load(): Promise<void> {
        this.app = await resource.load<AppConfig>(configUrl);
    }
}

export const config = new ConfigLoader();
