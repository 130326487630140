import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { renderIf, theme } from 'xact'
import { useMarquee, MarqeeMessage } from '../stores/marqueeStore'
import { config } from '../config'
import * as paths from '../paths'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group';
import { Button, Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import Footer from './Footer'
import { ReactComponent as CheckIcon } from '../assets/check.svg'
import { ReactComponent as LogoIcon } from '../assets/logo.svg'
import styles from './Main.module.css'

export default function Main() {
    const { pathname } = useLocation();
    const marquee = useMarquee();
    const [themeName, setTheme] = useState(theme.get());
    const [marqueeShow, setMarqueeShow] = useState(true);
    const [marqueeHover, setMarqueeHover] = useState(false);
    const [marqueeIndex, setMarqueeIndex] = useState(Math.floor(Math.random() * marquee.content.length));
    const nodeRef = useRef(null);

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });
    }, [pathname]);

    useEffect(() => {
        if (marquee.content.length === 0) {
            marquee.init();
        }
    }, [marquee]);

    useEffect(() => {
        const timerInterval = !marqueeShow ? 1500 : marqueeHover ? 500 : 5000;
        const marqueeInterval = setTimeout(() => {
            if (marqueeShow && marqueeHover) {
                return;
            }
            if (marqueeShow === false) {
                setMarqueeIndex((marqueeIndex + 1) % marquee.content.length);
            }
            setMarqueeShow(!marqueeShow);
        }, timerInterval);

        return () => clearInterval(marqueeInterval);
    }, [marqueeShow, marqueeIndex, marqueeHover, marquee.content.length])

    function changeTheme(themeName: string) {
        theme.set(themeName);
        setTheme(themeName);
    }

    function marqueeContent(): MarqeeMessage | undefined {
        return marquee.content.length > 0 ?
            marquee.content[marqueeIndex] :
            undefined;
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <Container className={styles.top}>
                    <Row sm={1} md={2}>
                        <Col className={styles.marquee} xs={8}>
                            <CSSTransition
                                nodeRef={nodeRef}
                                // appear={true}
                                in={marqueeShow}
                                timeout={0}
                                classNames={{
                                    // appear: styles.marqueeExitDone,
                                    // appearDone: styles.marqueeEnterDone,
                                    enter: styles.marqueeEnter,
                                    enterDone: styles.marqueeEnterDone,
                                    exitDone: styles.marqueeExitDone,
                                }}>
                                <div className={classNames(styles.target, 'justify-content-sm-center justify-content-md-start')} ref={nodeRef}>
                                    <NavLink
                                        to={marqueeContent()?.target ?? paths.home}
                                        onMouseEnter={() => setMarqueeHover(true)}
                                        onMouseOut={() => setMarqueeHover(false)}
                                    >
                                        {marqueeContent()?.text}
                                    </NavLink>
                                </div>
                            </CSSTransition>
                        </Col>
                        <Col className={classNames(styles.action, 'justify-content-sm-center justify-content-md-end')} xs={4}>
                            <NavLink to={paths.contact}>Contact</NavLink>
                            <NavLink to={paths.blog}>Blog</NavLink>
                            <NavLink to={paths.careers}>Careers</NavLink>
                        </Col>
                    </Row>
                </Container>
                <div className={classNames('brand-color', styles.brand)}>
                    <div className='d-flex flex-row align-items-center'>
                        <h1 className={classNames(styles.logo)}>
                            <NavLink to={paths.home}><LogoIcon /></NavLink>
                            <NavLink className='brand-logo' to={paths.home}>SkillPulse AI</NavLink>
                        </h1>
                        <div className={classNames(styles.menu, 'd-flex', 'flex-row')}>
                            <NavLink className={styles.link} to={paths.about}>About</NavLink>
                            <DropdownButton size='sm' variant='link' title='Solutions'>
                                <Dropdown.Item as={NavLink} to={paths.solutionadmin}>Administrative</Dropdown.Item>
                                <Dropdown.Item as={NavLink} to={paths.solutioncallcenter}>Call Center</Dropdown.Item>
                                <Dropdown.Item as={NavLink} to={paths.solutionfoodservice}>Food Services</Dropdown.Item>
                                <Dropdown.Item as={NavLink} to={paths.solutionretail}>Retail</Dropdown.Item>
                            </DropdownButton>
                            <NavLink className={styles.link} to={paths.pricing}>Pricing</NavLink>
                        </div>
                    </div>
                    <div className={styles.action}>
                        <Button size='sm' variant='outline-light' onClick={() => window.location.href = config.app.SignIn}>Account</Button>
                        <DropdownButton className={styles.theme} size='sm' variant='outline-light' title='Theme' autoClose='outside'>
                            <Dropdown.Item onClick={() => changeTheme('light')}>
                                <div className='d-flex flex-row'>
                                    <div className={styles.themecheck}>{renderIf(themeName === 'light', <CheckIcon />)}</div>
                                    Light
                                </div>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => changeTheme('dark')}>
                                <div className='d-flex flex-row'>
                                    <div className={styles.themecheck}>{renderIf(themeName === 'dark', <CheckIcon />)}</div>
                                    Dark
                                </div>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => changeTheme('auto')}>
                                <div className='d-flex flex-row'>
                                    <div className={styles.themecheck}>{renderIf(themeName === 'auto', <CheckIcon />)}</div>
                                    System
                                </div>
                            </Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
            </div>
            <div className={styles.content}>
                <Outlet />
            </div>
            <Footer />
        </div >
    );
}
