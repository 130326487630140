import log from 'loglevel';
import { api } from 'xact'
import { config } from '../config'
import * as xtypes from 'xtypes'

export async function getPost(name: string): Promise<string> {
    const contentUrl = `${config.app.Content}/blog/${name}.html`;
    log.info(`BLOG POST: ${contentUrl}`);
    const response = await fetch(contentUrl, {
        method: 'GET',
        headers: {
            Accept: 'text/html'
        }
    });
    if (!response.ok) {
        throw new Error(`Unable to access content: ${contentUrl}`)
    }
    return await response.text();
}

export async function getIndex(): Promise<xtypes.blog.IBlogSummary[]> {
    const indexUrl = `${config.app.Content}/blog/${config.app.Blog}.json`;
    try {
        const response = await api.httpGet('BLOG INDEX', new URL(indexUrl));
        return await api.json<xtypes.blog.IBlogSummary[]>(response);
    } catch (err) {
        log.error(err);
        return [];
    }
}