import { create } from 'zustand'
import * as blog from '../services/blog'
import * as xtypes from 'xtypes'

export interface BlogState {
    items: xtypes.blog.IBlogSummary[];
    map: Record<string, xtypes.blog.IBlogSummary>,
    error?: Error;
    init: () => Promise<void>;
}

export const useBlog = create<BlogState>((set) => ({
    items: [],
    map: {},
    init: async () => {
        try {
            const items = await blog.getIndex()
            const map = index(items);
            set({ items, map });
        } catch (err: any) {
            set({ error: err });
        }
    },
}));

export function index(items: xtypes.blog.IBlogSummary[]): Record<string, xtypes.blog.IBlogSummary> {
    const map = {} as Record<string, xtypes.blog.IBlogSummary>;
    for (const item of items) {
        map[item.content] = item;
    }
    return map;
}