import log from 'loglevel';
import { api } from 'xact'
import { config } from '../config'
import * as xtypes from 'xtypes'

export async function postRequest(contact: xtypes.site.IContact): Promise<void> {
    try {
        await api.httpPost('REQUEST CONTACT', api.url('api/us/contact', config.app.PortAPI), contact);
    } catch (err) {
        log.error(err);
    }
}
