import classNames from 'classnames'
import { NavLink, useNavigate } from 'react-router-dom'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import * as paths from '../paths'
import IconAdmin from '../assets/admin.svg'
import IconCallCenter from '../assets/callcenter.svg'
import IconFood from '../assets/foodservices.svg'
import IconRetail from '../assets/retail.svg'
import styles from './Home.module.css'

interface CardInfo {
  title: string;
  target: string;
  image: string;
  text: string;
}

export default function Home() {
  const navigate = useNavigate();
  return (
    <>
      <div className={classNames(styles.content, styles.top)}>
        <div className='display-4 fw-bold'>
          Unlock your team’s<br />
          full potential...
        </div>
        <div className={classNames('lead', styles.lead)}>
          Skill development has never been this
          effortless, effective, and affordable!
        </div>
        <div className={styles.info}>
          <div>
            Imagine a training solution designed specifically for your industry — one that’s customizable, affordable,
            and crafted to fit seamlessly into your operations.
          </div>
          <div>
            Our AI-driven program is built to elevate essential skills specific to businesses
            where traditional training has often felt out of reach.
          </div>
          <div>
            With interactive real-world scenarios, knowledge coaching, and tracking progress with actionable metrics,
            our platform delivers real results, preparing your team for every challenge and opportunity.
          </div>
        </div>
        <div>
          <Button onClick={() => navigate(paths.contact)}>Schedule Free Demo</Button>
        </div>
      </div>
      <div className={classNames(styles.content, styles.cards)}>
        <Container>
          <div className={classNames('fs-2', styles.lead)}>
            Custom programs designed for:
          </div>
          <Row className='g-4' xs={1} md={2}>
            {cards.map((card, idx) => (
              <Col key={idx}>
                <Card border='primary'>
                  <Card.Img variant='top' src={card.image} />
                  <Card.Body className={styles.body}>
                    <Card.Title><NavLink to={card.target}>{card.title}</NavLink></Card.Title>
                    <Card.Text>{card.text}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className={classNames(styles.content, styles.bottom)}>
        <div className={classNames('display-6', 'fw-bold')}>
          Be Amazed...
        </div>
        <div className={classNames('lead', styles.lead)}>
          Don't wait...
        </div>
        <div>
          Words word words...
        </div>
        <div>
          <Button onClick={() => navigate(paths.contact)}>Schedule Free Demo</Button>
        </div>
      </div>
    </>
  );
}

const cards: CardInfo[] = [
  {
    title: 'Food Service',
    target: paths.solutionfoodservice,
    image: IconFood,
    text:
      `
      This is a longer card with supporting text below as a natural
      lead-in to additional content.This content is a little bit
      longer.
      `
  },
  {
    title: 'Retail',
    target: paths.solutionretail,
    image: IconRetail,
    text:
      `
      This is a longer card with supporting text below as a natural
      lead-in to additional content.This content is a little bit
      longer.
      `
  },
  {
    title: 'Administrative',
    target: paths.solutionadmin,
    image: IconAdmin,
    text:
      `
      This is a longer card with supporting text below as a natural
      lead-in to additional content.This content is a little bit
      longer.
      `
  },
  {
    title: 'Call Center',
    target: paths.solutioncallcenter,
    image: IconCallCenter,
    text:
      `
      This is a longer card with supporting text below as a natural
      lead-in to additional content.This content is a little bit
      longer.
      `
  },
];