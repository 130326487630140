// general
export const home = '/';
export const contact = 'contact';
export const pricing = 'pricing';

// company info
export const about = 'about';
export const careers = 'careers';

// solutions
export const solutions = 'solutions';
export const solutionretail = 'retail';
export const solutioncallcenter = 'callcenter';
export const solutionfoodservice = 'foodservice';
export const solutionadmin = 'administrative';

// blog
export const blog = 'blog';

// landing
// export const landing1 = 'try';

// hidden
export const hidden = '~';
export const fonts = 'fonts';