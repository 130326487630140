import classNames from 'classnames'
// import { NavLink } from 'react-router-dom'
// import { Button, Card, Col, Container, Row } from 'react-bootstrap'
// import * as AppPaths from '../../appPaths'
import styles from './Solutions.module.css'

export default function Solutions() {
  return (
    <>
      <div className={classNames('title', styles.title)}>
      </div>
    </>
  );
}
