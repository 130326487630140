// import classNames from 'classnames'
// import { NavLink } from 'react-router-dom'
// import { Button, Card, Col, Container, Row } from 'react-bootstrap'
// import * as paths from '../paths'
// import IconLogo from '../assets/react.svg'
// import styles from './Home.module.css'

export default function Pricing() {
  return (
    <>
      <div className=''>

      </div>
    </>
  );
}
