import { useEffect } from 'react'
import classNames from 'classnames'
import { useBlog } from '../../stores/blogStore'
import { config } from '../../config'
import { NavLink } from 'react-router-dom'
import { Card, Col, Container, Row } from 'react-bootstrap'
import styles from './Index.module.css'

export default function Blog() {
  const blogIndex = useBlog();
  useEffect(() => {
    if (blogIndex.items.length === 0) {
      blogIndex.init();
    }
  }, [blogIndex]);

  return (
    <Container>
      <div className={classNames('title', styles.title)}>
        <div className='display-6 fw-bold pb-2'>
          Our Blog:
        </div>
        <div className='lead fw-bold pb-2'>
          The latest news and updates from <i>SkillPulse AI</i>
        </div>
      </div>
      <Row className='g-4' sm={1} md={2} xl={3}>
        {
          blogIndex.items.map((item, index) =>
            <Col key={`${item.content}-${index}`} sm={12} md={6} xl={4}>
              <Card className={styles.blog} border='primary'>
                <Card.Body className={styles.body}>
                  <Card.Title className={classNames('title', styles.blogtitle, 'mb-0')}>
                    <NavLink to={item.content}>{item.title}</NavLink>
                  </Card.Title>
                  <Card.Text as='div' className='small pb-2'>
                    <div className='small mb-1 text-body-secondary'>{new Date(item.timestamp).toLocaleDateString()}</div>
                    <div className={styles.summary}>{item.summary}</div>
                  </Card.Text>
                  <div className={styles.thumbnail}>
                    <div>
                      <NavLink to={item.content}>
                        <Card.Img src={`${config.app.Content}/blog/media/${item.thumbnail ?? 'placeholder.png'}`} />
                      </NavLink>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )
        }
      </Row>
    </Container >
  );
}
